<template>
  <loading :loading="isLoading">
    <div class="scenic-introduction">
      <Nav-bar :title="title" left-arrow :safe-area-inset-top="true" :border="false" @click-left="goBack"
        :style="{ paddingTop: isNative ? '44px' : '' }" />
      <div class="contain">
        <div class="briefNote" v-for="(item, index) in sceniceIntroduction.briefNote" :key="index + item.title">
          <div class="briefNote-title">{{ item.title }}</div>
          <div :class="showMoreBriefNote['showMoreBriefNote'+index]?'briefNote-containMore':'briefNote-contain'">
            <div v-for="(v, i) in item.notes" :key="i" :ref="'briefNote'+index" v-html="v.desc"></div>
          </div>
          <div class="moreBtn" @click="moreBriefNote(index)" v-if="!showMoreBriefNote['showMoreBriefNote'+index]">展开<img
              src="@/assets/detail/grownDownArrow.png" alt="" /></div>
        </div>
        <div class="openTimeNote" v-for="(item, index) in sceniceIntroduction.openTimeNote" :key="index + item.title">
          <div class="openTimeNote-title">{{ item.title }}</div>
          <div class="openTimeNote-contain" v-for="(v, i) in item.notes" :key="i">
            <!-- 有title的情况为表格 -->
            <div v-if="v.title" class="openTimeNote-contain-istitle">
              <div class="openTimeNote-contain-istitle-title">
                {{ v.title }}
              </div>
              <div class="openTimeNote-contain-istitle-desc">
                {{ v.desc }}
              </div>
            </div>
            <!-- 没有title的情况 -->
            <div v-else class="">
              <div class="noteDetail-contain-desc">
                {{ v.desc }}
              </div>
            </div>
          </div>
          <div class="openTimeNote-advice">
            {{ sceniceIntroduction.proposalPlayTime }}
          </div>
        </div>
        <div class="noteDetail" v-for="(item, index) in sceniceIntroduction.noteDetail" :key="index + item.title">
          <div class="noteDetail-title">{{ item.title }}</div>
          <div class="noteDetail-contain" v-for="(v, i) in item.notes" :key="i">
            <div class="noteDetail-contain-title" v-if="v.title" v-html="v.title">
            </div>
            <div class="noteDetail-contain-desc" v-if="v.desc" v-html="v.desc">{{v.desc}}</div>
            <!-- <div class="noteDetail-contain-single" v-if="!v.desc" v-html="v.title"></div> -->
          </div>
        </div>
      </div>
    </div>
  </loading>

</template>
<script>
import { NavBar } from "huoli-ui";
import nativeApi from "@/utils/nativeApi";
import service from "@/service";
import get from "@/utils/get";
import env from "@/utils/env";
import loading from "@/components/c-loading.vue";

export default {
  name: "scenic-introduction",
  components: {
    NavBar,
    loading,
  },
  data() {
    return {
      isNative: env.isNative,

      //简介215046
      title: this.$route.query.title,
      sceniceIntroduction: {},
      showMoreBriefNote: {
        showMoreBriefNote0: false,
        showMoreBriefNote1: false,
      },
      isLoading: true,
    };
  },
  methods: {
    //是否显示更多的简介
    moreBriefNote(index) {
      this.showMoreBriefNote["showMoreBriefNote" + index] = true;
    },
    goBack() {
      // this.$router.back();
      this.$page.back();
    },
    //简介215046
    getSceniceIntroduction(scenicSpotId, productId) {
      let obj = {
        scenicSpotId: scenicSpotId,
        productId: productId,
      };
      return service.r215046(obj).then((res) => get(res, "res.bd.data", []));
    },
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "1" });
    }
  },
  created() {
    this.getSceniceIntroduction(this.$route.query.scenicSpotId).then((data) => {
      this.sceniceIntroduction = data;
      this.isLoading = false;
      this.$nextTick(() => {
        if (this.$refs.briefNote0) {
          if (this.$refs.briefNote0[0].clientHeight < 66) {
            this.showMoreBriefNote.showMoreBriefNote0 = true;
          }
        }
        if (this.$refs.briefNote1) {
          if (this.$refs.briefNote1[0].clientHeight < 66) {
            this.showMoreBriefNote.showMoreBriefNote1 = true;
          }
        }
      });
    });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";

.scenic-introduction {
  .contain {
    width: 375 * $px;
    padding: 16 * $px;
    box-sizing: border-box;
  }
}
.briefNote {
  margin-top: 16 * $px;
  margin-bottom: 28 * $px;
  width: 343 * $px;
  &-title {
    height: 25 * $px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
  }
  &-contain {
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    line-height: 22 * $px;
    height: 66 * $px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ".";
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }
  &-containMore {
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    line-height: 22 * $px;
    // height: 66 * $px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .moreBtn {
    width: 343 * $px;
    height: 14 * $px;
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: rgba(52, 56, 75, 0.5);
    line-height: 14px;
    margin-top: 12 * $px;
    img {
      width: 10 * $px;
      height: 6 * $px;
      display: inline-block;
    }
  }
}
.openTimeNote {
  margin-bottom: 28 * $px;
  width: 343 * $px;
  &-title {
    height: 25 * $px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
    margin-bottom: 12 * $px;
  }
  &-contain {
    // display: flex;
    margin-bottom: 2 * $px;
    &-istitle {
      display: flex;
      &-title {
        width: 144 * $px;
        height: 38 * $px;
        background: #edf1f7;
        //   border-radius: 8px 0px 0px 0px;
        font-size: 14 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #262626;
        line-height: 38 * $px;
        overflow: hidden;
      }
      &-desc {
        width: 197 * $px;
        height: 38 * $px;
        background: #f7f9fc;
        margin-left: 2 * $px;
        //   border-radius: 0px 8px 0px 0px;
        font-size: 14 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #262626;
        line-height: 38 * $px;
        overflow: hidden;
      }
    }
  }
  &-advice {
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(52, 56, 75, 0.75);
    margin-top: 6 * $px;
  }
}
.noteDetail {
  margin-bottom: 28 * $px;
  width: 343 * $px;
  &-title {
    height: 25 * $px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
    margin-bottom: 12 * $px;
  }
  &-contain {
    display: flex;
    margin-bottom: 4 * $px;
    // align-items: center;
    &-title {
      width: 76 * $px;
      flex-shrink: 0;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(52, 56, 75, 0.75);
    }
    &-desc {
      // width: 271 * $px;
      word-break: break-all;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #262626;
      line-height: 22 * $px;
    }
    &-single {
      width: 343 * $px;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #262626;
      line-height: 22 * $px;
    }
  }
}
</style>
